<template>
  <router-view></router-view>
</template>

<script>
import { getDatabase, ref, get } from "firebase/database";

export default {
  name: "App",
  created() {
    document.title = "CEO Club CRM";

    if (window.origin.indexOf("ceoclubcrm") >= 0) {
      window.location.replace("https://crm.ceoclub.com.ua");
    }
  },
  mounted() {
    this.CheckWidth();

    var self = this;

    setTimeout(() => {
      window.addEventListener("resize", function () {
        self.CheckWidth();
      });
    }, 500);

    // Инициализация MutationObserver
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function () {
        var dataTable = document.getElementsByClassName("data_table");

        if (dataTable) {
          dataTable = dataTable[0];

          if (dataTable) {
            dataTable.classList.remove("data_table_scrolled");

            var hasHorizontalScroll =
              document.body.scrollWidth > document.body.offsetWidth;

            if (hasHorizontalScroll) {
              dataTable.classList.add("data_table_scrolled");
            }
          }
        }
      });
    });

    // Настройки для MutationObserver
    var config = { childList: true, subtree: true };

    // Выбираем элемент для наблюдения (например, body)
    var targetNode = document.body;

    // Запуск наблюдения
    observer.observe(targetNode, config);

    // Вызов функции при загрузке страницы
    document.addEventListener("DOMContentLoaded", function () {
      var dataTable = document.getElementsByClassName("data_table");

      if (dataTable) {
        dataTable = dataTable[0];

        if (dataTable) {
          dataTable.classList.remove("data_table_scrolled");

          var hasHorizontalScroll =
            document.body.scrollWidth > document.body.offsetWidth;

          if (hasHorizontalScroll) {
            dataTable.classList.add("data_table_scrolled");
          }
        }
      }
    });

    document.addEventListener("mouseup", () => {
      var menus = document.getElementsByClassName(
        "vue-simple-context-menu--active"
      );

      for (let index = 0; index < menus.length; index++) {
        const menu = menus[index];

        menu.classList.remove("vue-simple-context-menu--active");
      }
    });
  },
  methods: {
    CheckSession: function () {
      if (localStorage.webSessionId) {
        const db = getDatabase();
        const SessionRef = ref(db, "WebSessions/");
        get(SessionRef).then((Sessions) => {
          if (Sessions.exists()) {
            for (const [SessionId, Session] of Object.entries(Sessions.val())) {
              if (
                SessionId != undefined &&
                Session != undefined &&
                Session.id != undefined &&
                Session.id == localStorage.webSessionId
              ) {
                return;
              }
            }
          }

          localStorage.removeItem("webSessionId");

          this.$router.push({ name: "LogInPage" });
        });
      }
    },
    CheckWidth: function () {
      var dataTable = document.getElementsByClassName("data_table");

      if (dataTable) {
        dataTable = dataTable[0];

        if (dataTable) {
          dataTable.classList.remove("data_table_scrolled");

          var hasHorizontalScroll =
            document.body.scrollWidth > document.body.offsetWidth;

          if (hasHorizontalScroll) {
            dataTable.classList.add("data_table_scrolled");
          }
        }
      }
    },
  },
  watch: {
    "$route.name": function () {
      //this.CheckSession();
      setTimeout(() => {
        this.CheckWidth();
      }, 500);
    },
  },
};
</script>

<style>
@import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
@import "./styles_v2/slider.css";

@import "./styles/InputStyles.css";
@import "./styles/TextStyles.css";

@import "./styles_v2/text.css";
@import "./styles_v2/color.css";
@import "./styles_v2/menu_item.css";
@import "./styles_v2/tabs.css";
@import "./styles_v2/form.css";
@import "./styles_v2/checkbox.css";
@import "./styles_v2/toggle.css";
@import "./styles_v2/context_menu.css";
@import "./styles_v2/context_menu_icons.css";
@import "./styles_v2/rating.css";
@import "./styles_v2/check_area.css";
@import "./styles_v2/check_button.css";
@import "./styles_v2/table.css";
@import "./styles_v2/page.css";
@import "./styles_v2/analytic_page.css";
@import "./styles_v2/badge.css";
@import "./styles_v2/inputs.css";
@import "./styles_v2/buttons.css";
@import "./styles_v2/avatars.css";
@import "./styles_v2/calendar.css";
@import "./styles_v2/modal_view.css";
@import "./styles_v2/popup_modal_view.css";
@import "./styles_v2/notification.css";
@import "./styles_v2/file_upload.css";
@import "./styles_v2/loader.css";
@import "./styles_v2/filters.css";
@import "./styles_v2/images.css";
@import "./styles_v2/range_slider.css";
@import "./styles_v2/collapse.css";
@import "./styles_v2/phone.css";

@import "./styles_v2/load_panel.css";

@import "./styles_v2/chart.css";

@import "./styles_v2/home.css";

@import "./styles_v2_mobile/page.css";
@import "./styles_v2_mobile/analytic_page.css";
@import "./styles_v2_mobile/filters.css";
@import "./styles_v2_mobile/table.css";
@import "./styles_v2_mobile/loader.css";
@import "./styles_v2_mobile/modal_view.css";
@import "./styles_v2_mobile/changed_elements.css";
@import "./styles_v2_mobile/calendar.css";
@import "./styles_v2_mobile/chart.css";

@import "./styles/DataTable.css";
@import "./styles/EditChaptersView.css";
@import "./styles/InputStyles.css";
@import "./styles/ModalView.css";
@import "./styles/SubModalView.css";
@import "./styles/TextStyles.css";

@import "./styles_tailwind/primitives.css";
@import "./styles_tailwind/light_theme_tokens.css";

@import "./assets/fonts/Formular/fonts-formular.css";
@import "./assets/fonts/Inter/fonts-inter.css";

html,
body,
#app {
  width: 100%;
  height: 100%;

  margin: 0px;

  transition: all 0s;
}

* {
  transition: all 0.2s ease;

  -webkit-tap-highlight-color: transparent;
}

*:hover {
  transition: all 0.2s ease;
}

.Grabbing * {
  cursor: grabbing !important;
}

.tippy-box[data-theme~="ceo"] {
  font-family: "Formular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: -0.28px;

  display: flex;
  align-items: center;

  padding: 8px;

  background: #222124;
  border-radius: 4px;

  z-index: 99999;
}

.tippy-box[data-theme~="ceo"] > .tippy-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 4px;
}

.tippy-box[data-theme~="ceo"] > .tippy-content span {
  color: var(--base-colors-400, #898ca7);
}

.tippy-box[data-theme~="ceo"][data-placement^="top"] > .tippy-arrow {
  transition: 0s !important;
}

.tippy-box[data-theme~="ceo"][data-placement^="left"] > .tippy-arrow {
  transition: 0s !important;
}

.tippy-box[data-theme~="ceo"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: #222124;
}

.tippy-box[data-theme~="ceo"][data-placement^="bottom"] > .tippy-arrow::before {
  border-bottom-color: #222124;
}

.tippy-box[data-theme~="ceo"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: #222124;
}

.tippy-box[data-theme~="ceo"][data-placement^="right"] > .tippy-arrow::before {
  border-right-color: #222124;
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
